<template>
    <div class="content">
        <!-- 头部 -->
        <headerText></headerText>
        <!-- 中部 -->
        <div class="photo_img">
            <span class="fb">合作共赢</span>
            <p class="fs">再佳学面向全国招募100名城市“合伙人”，覆盖全国一二三线城市，每个城市设置独家合伙人，授权合伙人当地城市经营权！
            </p>
        </div>
        <div class="partner">
            <div class="partner_title">
                <p class="city fb">城市合伙人</p>
                <p class="line"></p>
            </div>
        </div>
        <!-- 第一个列表 -->
        <div class="city_content">
            <div class="city_list">
                <ul>
                    <li>
                        <div>
                            <img src="../../assets/img/win_01.png">
                            <p class="text_one fs">
                                根据各地不同的人口基数
                                缴纳相应的加盟费
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="../../assets/img/win_02.png" alt="">
                            <p class="text_two fs">寻找公益捐赠人(捐赠人可以是知名基本、名企、名人等,教育公益产品由最美课本免费提供)
                                <br>
                                推动当地用户转换工作
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="../../assets/img/win_03.png" alt="">
                            <p class="text_three fs">
                                维护当地政府、教育主管部门、学校方的资源关系
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 第二个列表 -->
            <div class="city_info">
                <ul>
                    <li>
                        <div>
                            <img src="../../assets/img/获利，利润，受益，赚钱.png" alt="">
                            <p class="fb" style="padding: 38px 0px 44px 0px;font-size: 28px">任务目标</p>
                            <p class="title_one fs">每年捐赠落地总量必须达到当地生人口的20%左右，以后每年在原有人数的基础上递增10%</p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="../../assets/img/呵护，成长，赚钱，营收.png" alt="">
                            <p class="fb" style="padding: 38px 0px 44px 0px;font-size: 28px">享受权益</p>
                            <p class="title_A fs">
                                享受当地市场全部收入的25%分成
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="../../assets/img/会议，上升，业绩.png" alt="">
                            <p class="fb" style="padding: 38px 0px 44px 0px;font-size: 28px">保障利益</p>
                            <p class="title_two fs">
                                专业业务培训支持
                                <br>
                                市场推广策略支持
                                <br>
                                产品售后客服支持
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 底部 -->
        <footerText></footerText>
    </div>
</template>

<script>

    export default {
        data() {
            return {

            };
        },
        mounted() {
            if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                this.$router.push('/winEn')
            }
        },
        methods: {

        }
    };
</script>

<style scoped lang="scss">
    @import '../../assets/css/win.scss';
</style>
